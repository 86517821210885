import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from "react-bootstrap"
import Header from "../components/header"
import Seo from "../components/seo"
import fam from "../images/family.jpeg"
import read from "../images/reading.jpg"
import group from "../images/group.jpg"
import bubblestwo from "../images/speechbubbles2.png"
import bubblesthree from "../images/speechbubbles3.png"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Header />
    <Row style={{margin: `0`, padding:'5px 1em', backgroundColor: '#98c5ef'}}>
      <Col xs={6}>
        <p style={{ textAlign:'left', margin: '0', color:`#01264f`, fontFamily:`Avenir Next Condensed`, fontWeight:`500`, fontSize:`14px`}}>English Accent Training | Speech+Language Therapy | Reading+Literacy Intervention</p>
      </Col> 
      <Col xs={6} style={{textAlign:'right'}}>
        <a href="tel:+2252786015" style={{color:`#01264f`, fontFamily:`Rockwell`, fontWeight:`800`, fontSize:`18px`, textDecoration:'none'}}>225.278.6015</a>
      </Col>
    </Row>
    <Container fluid>
      <Row style={{marginTop: `20px`}}>
        <Col sm={3} style={{margin: `0`, padding: `0`}}>  
        <img src={bubblestwo} alt="Singh Speech, Language and Reading Intervention" style={{height:'auto',width:'100%'}} />  
       </Col>
        <Col sm={6}> 
        <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', fontSize:'32px', fontStyle:'700', margin:'1.5em 0'}}>One-on-One Online + Hybrid/In-Person Speech, Language and Reading Intervention Services for Children and Adults</p>
        </Col>
        <Col sm={3}>
        <img src={bubblesthree} alt="Singh Speech, Language and Reading Intervention" style={{height:'auto',width:'100%', marginTop:'15px'}} />  
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{background: `#e6e0d7`}}>
          <h1 style={{ margin: '40px 20px', color:`#26bd0f`, textAlign:'center', fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`28px` }}>
            Language
          </h1>
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'900', margin:'10px 20px'}}>Language expression and comprehension build the foundation for developing reading, spelling, and writing later in childhood.  Many children with learning differences like dyslexia also have impaired language skills.</p> 
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'900', margin:'10px 20px'}}>This is why every client's expressive (verbal) and receptive (comprehension) language skills are thoroughly evaluated in addition to reading and writing.</p> 
          <a href="/coming-soon" style={{textDecoration:'none'}}>
          <div style={{textAlign:'center', width:'200px',border:'2px solid #c6324e',fontFamily:'Avenir Next Condensed', fontSize:'24px', padding:'0.25em', margin:'20px auto 40px'}}>
          <p style={{textAlign:'center', color:`#012b4f`,fontFamily:'Avenir Next Condensed', fontSize:'18px', fontStyle:'900', margin:'0.25em'}}>LEARN MORE</p>
          </div>
          </a>
        </Col>
        <Col md={6} style={{margin: `-20`, padding: `0`}}>
          <img src={fam} alt="Singh Speech, Language and Reading Intervention" style={{width:'100%', height:'auto', margin: `0`, padding: `-20px`}} />
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{margin: `0`, padding: `0`}}>
          <img src={read} alt="Reading and Literacy Intervention" style={{margin: `0`, padding: `0`}} />
        </Col>
        <Col md={6} style={{background: `#e8f4ff`}}>
          <h1 style={{ margin: '40px 20px', color:`#012b4f`, textAlign:'center', fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`28px` }}>
            Reading
          </h1>
         <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'900', margin:'10px 20px'}}>Literacy skills are thoroughly assessed in the main domains of reading and writing while remediation and one-on-one tutoring incorporates a mulitsensory, systematic, direct means of instruction based on the Orton-Gillingham Approach.</p> 
          <a href="/coming-soon" style={{textDecoration:'none'}}>
          <div style={{textAlign:'center', width:'200px',border:'2px solid #26bd0f',fontFamily:'Avenir Next Condensed', fontSize:'24px', padding:'0.25em', margin:'20px auto 40px'}}>
            <p style={{textAlign:'center', color:`#012b4f`,fontFamily:'Avenir Next Condensed', fontSize:'18px', fontStyle:'900', margin:'0.25em'}}>LEARN MORE</p>
          </div>
          </a>
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{background: `#e6e0d7`}}>
          <h1 style={{ margin: '40px 20px', color:`#c6324e`, textAlign:'center', fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`28px` }}>
            Speech
          </h1>
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'900', margin:'10px 20px'}}>A personalized, holistic approach is taken with each client with their unique speech needs, whether they are a child with a speech/articulation/phonological disorder or an English as a Second/Multiple Language speaker looking for help with improving his/her communicative effectiveness. </p>
          <a href="/coming-soon" style={{textDecoration:'none'}}>
          <div style={{textAlign:'center', width:'200px',border:'2px solid #26bd0f',fontFamily:'Avenir Next Condensed', fontSize:'24px', padding:'0.25em', margin:'20px auto 40px'}}>
            <p style={{textAlign:'center', color:`#012b4f`,fontFamily:'Avenir Next Condensed', fontSize:'18px', fontStyle:'900', margin:'0.25em'}}>LEARN MORE</p>
          </div> 
          </a>
        </Col>
        <Col md={6} style={{margin: `0`, padding: `0`}}>
        <img src={group} alt="Singh Speech, Language and Reading Intervention" style={{margin: `0`, padding: `0`}} />
        </Col>
      </Row>
      <Row>
          <Col>
          <h1 style={{ margin: '80px 20px', color:`#c6324e`, textAlign:'center', marginTop:'20px', fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`28px` }}>
            How to Start
          </h1>
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Rockwell', fontSize:'20px', fontStyle:'300', margin:'5px 20px 10px 20px'}}>
          1. GET IN TOUCH</p>  
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'300', margin:'10px 20px 20px 20px'}}>
          Contact me at 225-278-6015 or at hello@singhspeech.com to discuss your situation.  If you're ready to book an appointment for an evaluation, click the link below titled "Request Appointment". After discussion, I'll email a link to a HIPAA-secured portal where you can access and begin you or your child's new client documentation and screening.  Please complete to the best of your abilities and return back.</p>
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Rockwell', fontSize:'20px', fontStyle:'300', margin:'5px 20px 10px 20px'}}>
          2. FIRST MEETING </p>
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'300', margin:'10px 20px 20px 20px'}}>
          During our first session we will review/verify information from your new client documentation and complete a speech/language/reading/writing evaluation.  Goals will be formed shortly afterward and discussed with the client and their parent/guardian(if applicable).  These goals, along with strategies and techniquies for treatment, training or tutoring, will be more thoroughly addressed during the second session.  Periodic re-assessments will also occur in order to re-evaluate speech/language skills and update goals accordingly.  The client's progress is documented at every session and tracked.  We will collaborate with you/your child and any caregivers to create individualized goals with suggested strategies and resources to improve communication effectiveness. All documentation is web-based using an online intake management software that enables us to share documents electronically and receive them back completed and signed before our first appointment. This platform is HIPAA-compliant.
          </p> 
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Rockwell', fontSize:'20px', fontStyle:'300', margin:'5px 20px 10px 20px'}}>
          3. START SESSIONS
          </p>
          <p style={{textAlign:'left', color:`#012b4f`, fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'300', margin:'10px 20px 50px'}}>
          Fees begin at $35/session, depending on the length: 30, 45, or 60-minutes.  Click below to see the fee schedule. We are a private pay practice and do not accept insurance at this time. 
          </p>
          <div style={{textAlign:'center', width:'200px',border:'2px solid #c6324e',fontFamily:'Avenir Next Condensed', fontSize:'24px', padding:'0.25em', margin:'20px auto 60px'}}>
            <a style={{color: '#fff'}} href="/coming-soon">
              <p style={{textAlign:'center', color:`#012b4f`,fontFamily:'Avenir Next Condensed', fontSize:'18px', fontStyle:'900', margin:'0.25em'}}>FEE SCHEDULE</p>
            </a>
          </div>
      </Col>
        </Row>
      <Row style={{backgroundColor: '#01264f'}}>
      <Col>  <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', color:'#fcfcfc', fontSize:'48px', fontWeight:'600', margin:'3em auto 2em'}}>Schedule a Free 15-minute Consult:</p>

<div style={{textAlign:'center', width:'200px',border:'2px solid #26bd0f',fontFamily:'Avenir Next Condensed', fontSize:'18px', padding:'0.5em', margin:'10px auto 60px auto'}}>
  <a style={{color: '#fff'}} href="https://leah-singh.clientsecure.me" data-spwidget-scope-id="9499cadb-6b09-4b98-aca6-1512b5f8b55d" data-spwidget-scope-uri="leah-singh" data-spwidget-application-id="7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b" data-spwidget-scope-global data-spwidget-autobind >REQUEST APPOINTMENT</a>
</div>
        </Col>
      </Row>
      <Row style={{margin: `0.15em`, padding:'0'}}>
        <Col xs={4} style={{margin: `0`, padding:'0'}}></Col>
        <Col xs={4} style={{margin: `0`, padding:'0'}}>
        <p style={{ textAlign:'center', margin: '0.15em 0', padding: '0', fontFamily:`Avenir Next Condensed`, fontWeight:`500`, fontSize:`10px`}}>  
          <a href="https://www.lsdigitaltech.com" style={{textDecoration:'none', color:`#01264f`}}>  
            ©2021 LS Digital Tech, LLC.
          </a>
        </p>
      </Col>
      <Col xs={4} style={{textAlign:'right',margin: `0`, padding:'0'}}>
        <a href="tel:+2252786015" style={{color:`#01264f`, fontFamily:`Rockwell`, fontWeight:`700`, fontSize:`12px`, textDecoration:'none'}}>225.278.6015</a>
      </Col>
   </Row>
</Container> 
</>
)

export default IndexPage
